import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel/panel.component';
import { CommentsModule, LuxLayoutModule } from '@lims-common-ux/lux';
import { TranslateModule } from '@ngx-translate/core';
import { PanelAcceptComponent } from './panel-accept/panel-accept.component';
import { AcceptablePipe } from './acceptable.pipe';

@NgModule({
  imports: [TranslateModule.forChild({}), CommonModule, CommentsModule, LuxLayoutModule,],
  providers: [AcceptablePipe],
  declarations: [PanelComponent, PanelAcceptComponent, AcceptablePipe],
  exports: [PanelComponent, PanelAcceptComponent, AcceptablePipe],
})
export class PanelModule {}
