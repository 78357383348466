import { Injectable } from '@angular/core';

import { AppStateService } from '../app-state.service';
import { Subscription } from 'rxjs';
import { CanDeactivateService } from './can-deactivate.service';

@Injectable()
export class CanDeactivateGuard  {
  hasSavableChangesSub: Subscription = new Subscription();
  hasSavableChanges: boolean;

  constructor(private canDeactivateService: CanDeactivateService, private appStateService: AppStateService) {
    this.hasSavableChangesSub = this.appStateService.hasSavableChanges.subscribe((value) => {
      this.hasSavableChanges = value;
    });
  }

  canDeactivate(): boolean {
    return this.canDeactivateService.unsavedChangesAlert(this.hasSavableChanges);
  }
}
