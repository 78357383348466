<div *ngIf="panel" class="panel" [ngClass]="{ canceled: !panel.canModify }" [attr.data-cy-panel]="panel.testCode">
  <div class="panel-header" data-cy="panel-header">
    <span
    *ngIf="!panel.canModify"
    data-cy="canceled-indicator"
    class="indicator-icon canceled-indicator">
    <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
      <title>{{ 'LABELS.CANCELED' | translate }}</title>
      <use xlink:href="../../../assets/icons/spot_icons.svg#cancel"></use>
    </svg>
  </span>{{ panel.testCode }}</div>
  <div *ngIf="isExpanded" class="panel-body" data-cy="panel-body">
    <p>{{ panel.name }}</p>
    <div class="panel-comments">
      <lux-comments
        #panelComment
        data-cy="panel-comments"
        [allowAdd]="panel.canModify"
        [allowDelete]="panel.canModify"
        [lab]="lab"
        [commentsDataSource]="commentSearchUrl"
        [debounceDuration]="200"
        [context]="commentContext"
        [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
        [expandable]="true"
        (addComment)="onAddComment($event)"
        (removeComment)="onRemoveComment($event)"></lux-comments>
    </div>
  </div>
</div>
