import { Component, OnInit } from '@angular/core';
import { KeyboardAction, KeyboardService, ModalContainerService } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { KeyboardHelpItem } from '@lims-common-ux/lux/lib/keyboard-help-category/keyboard-help-category.component';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent implements OnInit {
  private openModal: KeyboardAction = {
    name: 'open-modal-help',
    matchCallback: ($evt: KeyboardEvent) => {
      if (this.modalService.openModal) {
        return $evt;
      }
      this.open();
    },
  };

  private closeModal: KeyboardAction = {
    name: 'modal-close',
    eventMatch: { key: 'Escape' },
    matchCallback: ($event: KeyboardEvent) => {
      if (this.appStateService.loading) {
        return;
      }
      this.keyboardService.preventDefaultAndPropagation($event);
      this.close();
    },
    removeOnMatch: true,
  };

  visible: boolean;

  keyboardHelpItemsContext: KeyboardHelpItem[] = [
    {
      description: 'HELP.MANUAL_DIFF_BUTTON_FOCUS',
      bindings: ['SHORTCUTS.F3'],
    },
    {
      description: 'HELP.RUN_REPEAT',
      bindings: ['SHORTCUTS.F5'],
    },
    {
      description: 'HELP.ASSAY_COMMENTS',
      bindings: ['SHORTCUTS.F6'],
    },
    {
      description: 'HELP.PANEL_COMMENTS',
      bindings: ['SHORTCUTS.F7'],
    },
    {
      description: 'HELP.ASSAY_NO_RESULT',
      bindings: ['SHORTCUTS.F8'],
    },
    {
      description: 'HELP.ASSAY_ACCEPT_ALL',
      bindings: ['SHORTCUTS.F11'],
    },
    {
      description: 'HELP.TOGGLE_EDIT',
      bindings: ['SHORTCUTS.ALT_E'],
    },
    {
      description: 'HELP.RUN_BUTTON_FOCUS',
      bindings: ['SHORTCUTS.ALT_P'],
    },
  ];
  keyboardHelpItemsNav: KeyboardHelpItem[] = [
    {
      description: 'HELP.ASSAY_FIRST_FOCUS',
      bindings: ['SHORTCUTS.F1'],
    },
    {
      description: 'HELP.NAV_NEXT',
      bindings: ['SHORTCUTS.ARROW_DOWN', 'SHORTCUTS.TAB'],
    },
    {
      description: 'HELP.NAV_PREV',
      bindings: ['SHORTCUTS.ARROW_UP', 'SHORTCUTS.SHIFT_TAB'],
    },
    {
      description: 'HELP.NAV_NEXT_CATEGORY',
      bindings: ['SHORTCUTS.ALT_ARROW_DOWN'],
    },
    {
      description: 'HELP.NAV_PREV_CATEGORY',
      bindings: ['SHORTCUTS.ALT_ARROW_UP'],
    },
    {
      description: 'HELP.NAV_NEXT_CLOSE',
      bindings: ['SHORTCUTS.CTRL_ARROW_DOWN'],
    },
    {
      description: 'HELP.NAV_PREV_CLOSE',
      bindings: ['SHORTCUTS.CTRL_ARROW_UP'],
    },
    {
      description: 'HELP.NAV_NEXT_QUEUE_ITEM',
      bindings: ['SHORTCUTS.ALT_ARROW_RIGHT'],
    },
    {
      description: 'HELP.RETURN_FOCUS',
      bindings: ['SHORTCUTS.ESCAPE'],
    },
  ];
  keyboardHelpItemsForms: KeyboardHelpItem[] = [
    {
      description: 'HELP.FORM_RESET',
      bindings: ['SHORTCUTS.ALT_R'],
    },
    {
      description: 'HELP.FORM_SAVE',
      bindings: ['SHORTCUTS.ALT_S'],
    },
    {
      description: 'HELP.FORM_CONTROL_TOGGLE',
      bindings: ['SHORTCUTS.SPACE'],
    },
  ];
  keyboardHelpItemsGeneral: KeyboardHelpItem[] = [
    {
      description: 'HELP.MENU',
      bindings: ['SHORTCUTS.F2'],
    },
    {
      description: 'LABELS.LAB_NOTES',
      bindings: ['SHORTCUTS.F9'],
    },
    {
      description: 'LABELS.ACCESSION_INFORMATION',
      bindings: ['SHORTCUTS.ALT_A'],
    },
    {
      description: 'HELP.HELP',
      bindings: ['SHORTCUTS.ALT_H'],
    },
    {
      description: 'LABELS.ADVANCED_ACCESSION_SEARCH',
      bindings: ['SHORTCUTS.ALT_F'],
    }
  ];

  constructor(
    private appStateService: AppStateService,
    private modalService: ModalContainerService,
    private keyboardService: KeyboardService
  ) {}

  ngOnInit(): void {
    this.keyboardService.addActions([this.openModal]);

    this.appStateService.accessionChangedEvent.subscribe(() => {
      this.close();
    });
  }

  open() {
    this.keyboardService.addActions([this.closeModal]);
    this.visible = true;
  }

  close() {
    this.visible = false;
    this.appStateService.triggerFocusFirstAssay();
  }
}
