<div luxLayout="row">
  <lux-header
    #header
    [env]="env"
    [accession]="appStateService.accessionHeader"
    [availableLabs]="appStateService.labs"
    [currentLab]="currentLab"
    [user]="appStateService.user"
    [configuration]="{
      accessionSearch: appStateService.accessionSearchLink,
      accessionLinks: true,
      accessionAdvancedSearch: appStateService.advancedAccessionSearchLink,
      attachments: appStateService.accessionHeader?._links.attachments,
      localeSelector: true
    }"
    [context]="'CBC'"
    [workspaceId]="appStateService.currentWorkspace?.id"
    (updateLab)="handleUpdateLab($event)"></lux-header>
</div>

<div id="lab-page" luxLayout="row" *ngIf="currentLab">
  <lux-flyout
    luxFlex="40px"
    #flyout
    [navigationItems]="navItems"
    [currentNavItem]="appStateService.currentWorkspace"
    (flyoutItemSelected)="changeWorkspace($event)"
    (flyoutButtonSelected)="startQueue($event)"
    (helpModalOpen)="helpModalOpen()">
  </lux-flyout>

  <div luxFlex>
    <router-outlet></router-outlet>
  </div>
</div>

<app-help-modal #helpModalContainer></app-help-modal>
