import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DefinedTextValue, ShortCodeMapping } from '@lims-common-ux/lux';
import { AccessionService } from '../../accession/accession.service';

@Component({
  selector: 'app-defined-multi-text',
  templateUrl: './defined-multi-text.component.html',
  styleUrls: ['../semi-quantitative-result-combo/semi-quantitative-result-combo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DefinedMultiTextComponent),
      multi: true,
    },
  ],
})
export class DefinedMultiTextComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input()
  resultOptions: DefinedTextValue[] = [];

  @Input()
  placeholder = '';

  @Input()
  noResult = false;
  @Output()
  noResultChange = new EventEmitter<boolean>();

  @Output()
  noneSeen = new EventEmitter();

  @Input()
  hidden = true;

  @Input()
  tabindex = 1;

  val: string[] = null;

  @Input()
  initialValue: string[] = null;

  @Input()
  name;

  @Input()
  shortCodes: ShortCodeMapping;

  @Input()
  showPrefix = false;

  @Input()
  disabled: boolean;

  @ViewChild('resultInput', { static: false })
  input: ElementRef;

  @ViewChild('wrapper', { static: false })
  cmpWrapper: ElementRef;

  @ViewChildren('resultsListItem')
  resultsListItems!: QueryList<ElementRef>;

  @ViewChildren('deleteIcons')
  deleteIcons!: QueryList<ElementRef>;

  filteredResultOptions: DefinedTextValue[] = [];

  showError = false;
  showRemovedSavedError = false;
  control: AbstractControl;
  initialFocus = false;

  @Input()
  editMode = false;

  @Input()
  repeatRequested: boolean;

  get value() {
    return this.val;
  }

  set value(val: string[]) {
    this.val = val;
    if (this.val?.length > 0) {
      this.noResult = false;
      this.noResultChange.emit(this.noResult);
    }
  }

  constructor(private assayService: AccessionService, private injector: Injector) {}

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.control = model.control;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.control && (changes.noResult?.currentValue || changes.repeatRequested?.currentValue)) {
      setTimeout(() => {
        this.resetErrorState();
        this.input.nativeElement.value = '';
      }, 0);
    }
  }

  isNoneSeenResult(resultCode: string) {
    const selectedResult = this.resultOptions.find((resultOption) => resultOption.code === resultCode);
    return selectedResult && selectedResult.noneSeen;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  writeValue(value: string | string[], remove: boolean = false) {
    // handles no result rewrite
    if (!value && this.value) {
      this.value = null;
      return;
    } else if (Array.isArray(value)) {
      // This is true when the model has an initial value set.
      this.value = value;
      return;
    }

    if (value) {
      const newVal = value;
      // Is the newly selected value noneSeen?
      const isNoneSeen = this.isNoneSeenResult(newVal);

      if (!this.value || this.value.length === 0) {
        this.value = [newVal];
        return;
      }

      if (newVal !== null && newVal && !isNoneSeen && this.value?.indexOf(newVal) === -1) {
        // If none seen was previously selected, it would be the only thing in the array, so we remove it if its there.
        const filteredVal = this.value.filter((existingValue: string) => {
          return !this.isNoneSeenResult(existingValue);
        });

        filteredVal.unshift(newVal);
        this.value = filteredVal;
      } else if (isNoneSeen) {
        // The newly selected result is noneSeen, so the only allowed input value is noneSeen.
        this.value = [newVal];
      }
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  close($event = null) {
    if (!this.hidden && $event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }
    this.hidden = true;
  }

  open() {
    this.hidden = false;
  }

  isClosed() {
    return this.hidden;
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  verifyCompleteEntry() {
    if (this.input.nativeElement.value) {
      this.input.nativeElement.click();

      requestAnimationFrame(() => {
        this.initialFocus = false;
        this.close();
        this.setErrorState({ removedSavedValues: true });
      });
    } else if (!this.input.nativeElement.value) {
      this.resetErrorState();
    }
  }

  handleFocusOut($event) {
    this.onTouched($event);
    if (this.control.dirty && !this.control.errors) {
      this.onChange(this.value);
    }
    setTimeout(() => {
      if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
        this.initialFocus = false;
        this.close();
      }
    }, 0);
  }

  // alt + e or double clicking puts this component into edit mode
  // which allows us to remove Result values
  toggleEditMode($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.disabled) {
      return;
    }

    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.focusInput();
    }
    return false;
  }

  handleInput() {
    const shortCode = this.input.nativeElement.value.trim().toUpperCase();
    if (shortCode === '') {
      this.filteredResultOptions = this.resultOptions;
    } else {
      this.filteredResultOptions = this.resultOptions.filter((definedText: DefinedTextValue) => {
        return this.shortCodes && this.shortCodes[shortCode] && definedText.code === this.shortCodes[shortCode];
      });
    }

    if (this.filteredResultOptions.length) {
      this.open();
      this.setErrorState({ inputError: false });
    } else {
      if (this.input.nativeElement.value) {
        this.setErrorState({ inputError: true });
      } else {
        this.setErrorState({ inputError: false });
      }
    }
  }

  private clearErrors() {
    this.control.setErrors(null);
    this.showRemovedSavedError = this.showError = false;
  }

  // visible for testing
  setErrorState(error) {
    let currentErrors = this.getErrors();

    if (!currentErrors) {
      currentErrors = {
        inputError: false,
        removedSavedValues: false,
      };
    }

    const newErrorState = Object.assign(currentErrors, error);

    if (newErrorState?.inputError === false && newErrorState?.removedSavedValues === false) {
      this.control.setErrors(null);
    } else {
      this.control.setErrors(newErrorState);
    }

    this.showError = !!newErrorState?.inputError;

    if (newErrorState && newErrorState.removedSavedValues) {
      this.showRemovedSavedError = true;
    } else if ((this.value && this.value.length) || this.noResult) {
      this.showRemovedSavedError = false;
    }
  }

  resetErrorState() {
    this.showError = false;
    this.showRemovedSavedError = false;
    this.control.setErrors(null);
    this.setErrorState(this.control.errors);
  }

  // visible for testing
  getErrors() {
    return this.control?.errors;
  }

  removeResultValue(item: string, index: number) {
    if (this.editMode) {
      this.value.splice(index, 1);

      if (!this.value.length) {
        this.value = null;
      }

      if (!this.value?.length && this.initialValue?.length && !this.noResult) {
        this.setErrorState({ removedSavedValues: true });
      } else {
        this.clearErrors();
        this.updateDisplay();
        this.onChange(this.value);
      }
      this.control.markAsDirty();

      // we want focus to be on next Result Value, otherwise set focus to the input when removing
      setTimeout(() => {
        let nextEleToFocus: ElementRef;
        if (!this.value?.length) {
          nextEleToFocus = this.input;
        } else {
          const nextIndex = index === this.value.length ? this.value.length - 1 : index;
          nextEleToFocus = this.deleteIcons.toArray()[nextIndex];
        }
        nextEleToFocus.nativeElement.focus();
      }, 0);
    }
  }

  handleEnter($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.disabled) {
      return;
    }

    if (this.isClosed() && !this.input.nativeElement.value) {
      this.filteredResultOptions = this.resultOptions;
      this.open();
    } else if (!this.isClosed()) {
      if (this.filteredResultOptions.length) {
        this.selectOption(this.filteredResultOptions[0], $event);
      }
    } else if (this.isClosed() && this.input.nativeElement.value && $event?.target?.value && this.control.errors) {
      setTimeout(() => {
        this.resetErrorState();

        setTimeout(() => {
          this.handleInput();
        }, 0);
      }, 0);
    }
  }

  handleFocus($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (!this.initialFocus) {
      this.initialFocus = true;
    }

    if (!this.value?.length && this.initialFocus && !this.control.errors) {
      this.filteredResultOptions = this.resultOptions;
      this.open();
    }
  }

  handleArrowDown($event) {
    if (this.disabled) {
      return;
    }

    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.filteredResultOptions.push(this.filteredResultOptions.splice(0, 1)[0]);

      return false;
    }
  }

  handleArrowUp($event) {
    if (this.disabled) {
      return;
    }

    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      const lastItem: DefinedTextValue = this.filteredResultOptions.pop();

      this.filteredResultOptions.unshift(lastItem);

      return false;
    }
  }

  getShortCodeByValue(val: string) {
    let prop;
    let match = '';

    for (prop in this.shortCodes) {
      if (val === this.shortCodes[prop]) {
        match = prop;

        break;
      }
    }
    return match;
  }

  selectOption(definedValue: DefinedTextValue, $event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.disabled) {
      return;
    }

    this.editMode = false;
    this.writeValue(definedValue.code);
    this.control.markAsDirty();
    this.close();
    this.focusInput();
    this.clearErrors();
    this.updateDisplay();
    if (this.value?.length && this.isNoneSeenResult(this.value[0])) {
      this.noneSeen.emit();
    }
    this.onChange(this.value);
  }

  getObservationDisplayTextByValue(value: string): string {
    return this.assayService.getObservationDisplayTextByValue(this.resultOptions, value);
  }

  private updateDisplay() {
    this.input.nativeElement.value = '';
  }
}
