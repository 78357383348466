import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { DefinedTextResultComponent, Lab } from '@lims-common-ux/lux';

import { AppStateService } from '../../../app-state.service';
import { Assay, AssayStatus } from '../../../interfaces/assay.interface';
import { AssayWrapperService } from '../assay-wrapper.service';
import { FreeTextComponent } from '../free-text/free-text.component';
import { NumericResultInputComponent } from '../numeric-result-input/numeric-result-input.component';
import { SemiQuantitativeResultComboComponent } from '../semi-quantitative-result-combo/semi-quantitative-result-combo.component';
import { SemiQuantitativeResultInputComponent } from '../semi-quantitative-result-input/semi-quantitative-result-input.component';

@Component({
  selector: 'app-assay-line-item',
  templateUrl: './assay-line-item.component.html',
  styleUrls: ['./assay-line-item.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssayLineItemComponent implements AfterViewInit {
  @ViewChild('assayWrapper', { static: false })
  assayWrapper: ElementRef;

  @ViewChild('displayValueLink', { static: false })
  displayValueLink: ElementRef;

  @ViewChild('resultInput', { static: false })
  resultInput:
    | NumericResultInputComponent
    | DefinedTextResultComponent
    | SemiQuantitativeResultComboComponent
    | SemiQuantitativeResultInputComponent
    | FreeTextComponent;

  @Input()
  assay: Assay;

  @Input()
  absoluteAssay: Assay;

  @Input()
  firstCard: boolean;

  @Input()
  lastCard: boolean;

  @Input()
  lab: Lab;

  @Output()
  valueChange = new EventEmitter<true>();

  presentationClass = '';

  showClinicalReference: boolean;

  constructor(private appStateService: AppStateService, private assayWrapperService: AssayWrapperService) {}

  ngAfterViewInit() {
    // Different result inputs may transform entered values, here we are setting a presentation value for use
    // in assay details and other areas of the app where we need access to the transformed result input value on load
    if (this.resultInput && this.resultInput.input?.nativeElement) {
      const updatedValue = this.assay?.result?.value;

      if (this.assay && this.assay.presentationValue !== updatedValue) {
        this.assay.presentationValue = updatedValue;
      }

      this.resultInput.control?.valueChanges.subscribe((newValue) => {
        if (newValue) {
          // we need to unset this in case some clicks "repeat", then goes back and adds a result value.
          this.assay.repeatRequested = false;
        }
        this.setCardPresentation();
        this.setShowClinicalReference();
      });
    }
  }

  /* EVALUATE AN ASSAY CARD ASSAY STATUS, RESULT, UPDATED RESULT, AND THE ASSAY CARD RESULT INPUT CONTROL */
  /* TO DETERMINE CARD WRAPPER PRESENTATION. THE PRESENTATION CLASS STRING IS DIRECTLY TIED TO CSS STYLE */
  /* DEFINITIONS. ASSIGNING CSS CLASSES THIS WAY CAN BE VERY FAST AND AVOIDS COMPLICATIONS WITH ANGULAR */
  /* CHANGE DETECTION AND SCREEN PAINTS WITHOUT RELYING ON AN OBSERVABLE. */
  /* NOTE: THERE IS NO EXPLICIT 'SELECTED' STATE. SELECTED STYLE PRESENTATION IS A FUNCTION */

  /* OF FOCUS-WITHIN AND APPSTATE CURRENTASSAY */
  getCardWrapperPresentation() {
    const classes = [];

    if (this.assay) {
      // performed-at-different-lab
      if (
        this.assay.status === 'OPENED' &&
        this.lab.id !== this.assay.expectedPerformingLab &&
        this.assay?.updatedResult.value === null
      ) {
        classes.push('performed-at-different-lab');
      }

      if (this.assay.status === AssayStatus.CANCELED) {
        classes.push('CANCELED');
      }

      // ALERT
      if (this.assay.status === 'TECHNICIAN_REVIEW' || this.absoluteAssay?.status === 'TECHNICIAN_REVIEW') {
        classes.push('ALERT');
      }

      // NO_RESULT
      if (!this.assay.updatedResult?.value && this.assay.updatedResult?.noResult) {
        classes.push('NO_RESULT');
      }

      // REPEAT_REQUESTED
      if (
        (this.assay.status === 'REPEAT_REQUESTED' || this.assay.repeatRequested) &&
        !this.assay.updatedResult?.noResult &&
        !this.assay.updatedResult?.value
      ) {
        classes.push('REPEAT_REQUESTED');
      }

      // HAS_CHANGES
      if (!this.resultInput?.control.errors && this.resultInput?.control.valid && this.resultInput?.control.dirty) {
        classes.push('HAS_CHANGES');
      }

      // SAVED
      if (this.hasBeenSaved() && this.assay.status !== 'TECHNICALLY_ACCEPTED' && this.assay.status !== 'RELEASED') {
        classes.push('SAVED');
      }

      if (this.assay.status === 'OPENED' && this.assay.resultRequired === 'NON_ESSENTIAL') {
        classes.push('NON_ESSENTIAL');
      }

      // HAS_COMMENTS
      if (this.assay.comments?.length > 0) {
        classes.push('HAS_COMMENTS');
      }

      // HAS_FLAGS
      if (this.assay.result?.flags?.length > 0) {
        classes.push('HAS_FLAGS');
      }

      // ATTENTION
      if (classes.indexOf('SAVED') < 0 && this.assay.status === 'OPENED') {
        classes.push('ATTENTION');
      }

      // HAS_TRANSFORMED_VALUE
      if (this.assay.result.transformedValue) {
        classes.push('HAS_TRANSFORMED_VALUE');
      }

      // TECHNICALLY_ACCEPTED
      if (this.assay.status === 'TECHNICALLY_ACCEPTED') {
        classes.push('TECHNICALLY_ACCEPTED');
      }

      // RELEASED
      if (this.assay.status === 'RELEASED') {
        classes.push('RELEASED');
      }
    }
    this.presentationClass = classes.join(' ');
  }

  private hasBeenSaved(): boolean {
    const savedAssays = this.assayWrapperService.getSavedAssays();
    if (!savedAssays) {
      return false;
    } else {
      return savedAssays.find((savedAssay) => savedAssay.testCode === this.assay.testCode) !== undefined;
    }
  }

  setShowClinicalReference(): void {
    const assay = this.assay;
    if (!assay) {
      this.showClinicalReference = false;
    } else {
      this.showClinicalReference =
        !!(
          !this.absoluteAssay &&
          assay.clinicalReference &&
          (assay.result?.value || assay.updatedResult?.value) &&
          !assay.result?.value?.noResult &&
          !assay.result?.value?.emptyResult &&
          assay.status !== 'REPEAT_REQUESTED' &&
          !assay.updatedResult?.noResult &&
          !assay.updatedResult?.emptyResult &&
          !assay.repeatRequested
        ) ||
        !!(
          this.absoluteAssay &&
          this.absoluteAssay.clinicalReference &&
          assay?.status !== 'REPEAT_REQUESTED' &&
          (assay.result?.value || assay.updatedResult?.value) &&
          !assay.result?.value?.noResult &&
          !assay.result?.value?.emptyResult &&
          !assay.updatedResult?.noResult &&
          !assay.updatedResult?.emptyResult &&
          !assay.repeatRequested
        );
    }
  }

  // Set card presentation based on result and status values,
  // and update presentation on user actions such as changing a result
  // and/or saying an assay has "No Result"
  setCardPresentation() {
    setTimeout(() => {
      if (this.assay) {
        this.getCardWrapperPresentation();
        this.setShowClinicalReference();
      }
    }, 0);
  }

  markAsTouchedDirty() {
    setTimeout(() => {
      this.setCardPresentation();
    }, 0);
  }

  markAsPristineUntouched() {
    setTimeout(() => {
      this.resultInput.control.markAsPristine();
      this.resultInput.control.markAsUntouched();
      this.setCardPresentation();
    }, 0);
  }

  handleClick($event) {
    $event.preventDefault();
    this.selectResultInput();
  }

  selectResultInput() {
    if (this.appStateService.accession?.runMustBePicked) {
      return;
    }
    setTimeout(() => {
      if (this.resultInput) {
        this.resultInput.focusInput();
      } else if (this.displayValueLink) {
        this.displayValueLink.nativeElement.focus();
      }
    }, 0);
  }

  handleFocusIn() {
    if (
      !this.appStateService.currentAssay ||
      (this.appStateService.currentAssay?.testCode !== this.assay.testCode &&
        this.assayWrapper.nativeElement.contains(document.activeElement))
    ) {
      this.select();
    }
  }

  focusNext($event) {
    this.valueChange.emit($event);
  }

  select() {
    this.appStateService.currentAssay = this.assay;
  }

  get selected(): boolean {
    return this.appStateService.currentAssay?.testCode === this.assay?.testCode;
  }
}
