import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '../../app-state.service';
import { Observable, of } from 'rxjs';
import { Workspace } from '@lims-common-ux/lux';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class WorkspaceResolver  {
  constructor(private appStateService: AppStateService, private titleService: Title) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Workspace> {
    const workspaceId = route.paramMap.get('workspace');
    this.appStateService.currentWorkspace = this.appStateService.workspaces.find(
      (ws) => ws.id === workspaceId && ws.workspaceType === 'CBC'
    );
    this.titleService.setTitle(this.appStateService.defaultPageTitle);
    return of(this.appStateService.currentWorkspace);
  }
}
