import { Component, OnDestroy, OnInit } from '@angular/core';
import { Workspace } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-empty-queue',
  templateUrl: './empty-queue.component.html',
  styleUrls: ['./empty-queue.component.scss'],
})
export class EmptyQueueComponent implements OnInit, OnDestroy {
  workspace: Workspace;
  visible = false;
  routeDataSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.routeDataSub = this.route.data.subscribe((data) => {
      if (!data?.emptyQueue?.empty) {
        this.router.navigate(['/', 'lims-results', this.appStateService.lab.id, 'workspaces', data.emptyQueue.workspace.id, 'queue']);
      } else {
        this.workspace = data.emptyQueue.workspace;
        this.visible = true;
      }
    });
  }

  ngOnDestroy() {
    this.routeDataSub.unsubscribe();
  }
}
